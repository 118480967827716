import React from 'react';
import { Helmet } from 'react-helmet-async';

const AboutUs = () => (
    <>
        <Helmet>
            <title>About Us - Upcoming Book</title>
        </Helmet>
        <article id="privacy">
            <h1 className="h4 mt-4">About Us</h1>
            <p>
                Welcome to <strong>UpcomingBook.com</strong>, your go-to destination for discovering the latest and most anticipated book releases. 
                Our mission is to connect book lovers with upcoming titles across all genres, making it easy to stay ahead of the literary curve.
            </p>
            <p>
                We believe that every great story deserves a spotlight, whether it’s from a bestselling author or an emerging voice. 
                Our platform provides detailed information on release dates, author insights, and category-based browsing, 
                ensuring you never miss a book that matters to you.
            </p>
            <p>
                At UpcomingBook.com, we’re passionate about creating a community where readers can explore, 
                discover, and celebrate new books. Join us in the excitement of uncovering your next favorite read!
            </p>
        </article>
    </>

);

export default AboutUs;
