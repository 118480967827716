import React, { useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Book.css';

const Book = ({ id, title, authors, publishedDate, slug, img_checked, isLoggedIn, handleShowLoginModal, isFavorite, path }) => {

  const [isFav, setIsFav] = useState(isFavorite);
  const [hidden, setHidden] = useState(false);

  const formattedDate = (() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayYmd = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    const tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1); 
    const tomorrowYmd = `${tomorrow.getFullYear()}-${String(tomorrow.getMonth() + 1).padStart(2, '0')}-${String(tomorrow.getDate()).padStart(2, '0')}`;
    const published = new Date(publishedDate);
    const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;
    
    if (publishedYmd === todayYmd) {
      return <span className="text-violet">Today</span>;
    } else if (publishedYmd === tomorrowYmd) {
      return <span className="text-violet">Tomorrow</span>;
    } else {
      return published.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    }
  })(); // formattedDate

  // YYYY-MM-DD
  const published = new Date(publishedDate);
  const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;

  const redBorder = img_checked ? '' : 'attention';

  const handleFavoriteChange = async () => {
    if (!isLoggedIn) return;
    const userInfoData = localStorage.getItem('userInfo');
    if (!userInfoData) return;
    const userInfo = JSON.parse(userInfoData);
    if (!userInfo) return;
  
    const newIsFav = !isFav;
    setIsFav(newIsFav);
  
    const favData = {
      book_id: id,
      email: userInfo.email,
      token: userInfo.token,
      is_favorite: newIsFav,
    };
  
    try {
      await saveFavorite(favData); // Perform the async operation.
    } catch (error) {
      console.error("Error:", error);
      setIsFav(!newIsFav); // Revert the state if the API call fails.
    }
  };
  
  const saveFavorite = async (favData) => {
    try {
      await axios.post("https://upcomingbook.com/api/toggle-favorite", favData);
      if (path === '/favorites' && !favData.is_favorite) {
        setHidden(true); // Hide the card
      }
    } catch (error) {
      throw error; // Re-throw to handle in `handleFavoriteChange`.
    }
  };
  
  const favClass = "fav-toggle bi bi-bookmark" + (isFav ? '-fill text-violet' : '');
  const favTitle = isFav ? 'Remove from Favorites' : 'Add to Favorites';
 
  if (hidden) {
    return null; // Do not render anything if the card is hidden
  }

  return (
    <Card className={`${isFav ? 'favorite' : ''} mb-3 ${redBorder}`}>
      <Link to={`/book/${slug}`} className="book-link">
        <div className="position-relative">
          <img
            src={`https://www.upcomingbook.com/images/books/tmb/${slug}.jpg`}
            alt={title}
            width="128"
          />
        </div>
      </Link>
      <Card.Body className="py-0">
        <div className="d-flex justify-content-between">
          <Card.Text className="date">
            <time dateTime={publishedYmd}>{formattedDate}</time>
          </Card.Text>
          <OverlayTrigger overlay={ <Tooltip>{favTitle}</Tooltip> }>
            <i className={favClass} onClick={isLoggedIn ? handleFavoriteChange : handleShowLoginModal}></i>
          </OverlayTrigger>
        </div>
        <Link to={`/book/${slug}`} className="book-link">
          <Card.Title className="mb-1">
            {title}
          </Card.Title>
          <Card.Text as="div" className="authors">
              <ul>
                {authors.split(';').slice(0, 3).map((author, index) => (
                  <li key={index}>
                    {author.trim()}
                  </li>
                ))}
              </ul>
          </Card.Text>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Book;
