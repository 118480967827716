import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { renderAuthorLink, renderCategoryLinks } from '../utils/renderLinks';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import AdminBook from './AdminBook';
import Retailers from './Retailers';
import RelatedBooks from './RelatedBooks';
import './BookPage.css';

const BookPage = ({isLoggedIn, handleShowLoginModal}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [book, setBook] = useState(null);
  const [imgSizes, setImgSizes] = useState([]);
  const [authorsInfo, setAuthorsInfo] = useState(null);
  const [isFav, setIsFav] = useState(false);
  
  const { slug } = useParams();
  const navigate = useNavigate();

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const descriptionRef = useRef(null);

  const handleDescriptionToggle = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const [isAboutAuthorsOverflowing, setIsAboutAuthorsOverflowing] = useState(false);
  const [isAboutAuthorsExpanded, setIsAboutAuthorsExpanded] = useState(false);
  const aboutAuthorsRef = useRef(null);

  const handleAboutAuthorsToggle = () => {
    setIsAboutAuthorsExpanded(!isAboutAuthorsExpanded);
  };


  useEffect(() => {
    // Check if slug matches the pattern <anything>-<id>
    const match = slug.match(/^(.*)-(\d+)$/);
    if (match) {
      const id = match[2]; // Extract the ID

      // Fetch the correct slug based on the ID
      const fetchCorrectSlug = async () => {
        try {
          const response = await fetch(`https://upcomingbook.com/api/book-slug/${id}`);
          if (response.ok) {
            const { slug: correctSlug } = await response.json();
            // Redirect to the correct slug if it doesn’t match
            if (correctSlug !== slug) {
              navigate(`/book/${correctSlug}`, { replace: true });
            }
          } else {
            console.error("Book not found");
          }
        } catch (error) {
          console.error("Error fetching slug:", error);
        }
      };

      fetchCorrectSlug();
    } // if (match)

    const params = {
      slug: slug,
    }
    const userInfoData = localStorage.getItem('userInfo');
    if (userInfoData) {
      const userInfo = JSON.parse(userInfoData);
      params.u = userInfo.email;
      params.token = userInfo.token;
    }

    axios
      .get('https://upcomingbook.com/api/book', {
        params: params
      })
      .then(response => {
        const data = response.data;
        setBook(data.book);
        setIsLoading(false);
        setIsFav(data.book.is_favorite);
        setImgSizes(data.images);
        if (data.authors) setAuthorsInfo(data.authors);
      })
      .catch(error => {
        console.error('Error fetching book:', error);
      });
  }, [slug, navigate]); // useEffect

  useLayoutEffect(() => {
    if (descriptionRef.current) {
      // Use setTimeout to delay the check until after the component is fully rendered
      setTimeout(() => {
        // Check if description content overflows the set max-height
        setIsDescriptionOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
      }, 0);
    }
    if (aboutAuthorsRef.current) {
      // Use setTimeout to delay the check until after the component is fully rendered
      setTimeout(() => {
        // Check if the content overflows the set max-height
        setIsAboutAuthorsOverflowing(aboutAuthorsRef.current.scrollHeight > aboutAuthorsRef.current.clientHeight);
      }, 0);
    }
  });

  if (isLoading) {
    return <small className="text-muted">Loading...</small>
  }

  if (!book) {
    return (
      <div className="text-center">
        <Alert variant="warning" className="d-inline-block mt-5">
          <p>Sorry, we couldn't find a book that matches this URL.</p>
          <p>Try using search.</p>
        </Alert>
      </div>
    );
  }

  const formattedDate = (() => {
    const today = new Date();
    const published = new Date(book.published_date);
  
    // Clear the time part for accurate day comparison
    today.setHours(0, 0, 0, 0);
    published.setHours(0, 0, 0, 0);
  
    // Calculate the difference in days
    const timeDifference = (published - today) / (1000 * 60 * 60 * 24);
  
    if (timeDifference === 0) {
      return <span className="text-violet">Today</span>;
    } else if (timeDifference === 1) {
      return <span className="text-violet">Tomorrow</span>;
    } else {
      return published.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }
  })(); // formattedDate

  // YYYY-MM-DD
  const published = new Date(book.published_date);
  const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;

  // Authors
  const authors = book.authors.split('; ').map((author, index) => (
    <span key={author}>
      {index > 0 && ', '}
      {renderAuthorLink(author)}
    </span>
  ));
  
  // Categories
  let categories = [];
  if (book.info.categories && book.info.categories.length) {
    book.info.categories.forEach(category => {
      // Remove ' / General' from the end of each category, if present
      const cleanedCategory = category.replace(/ \/ General$/, '');

      // Check if the cleanedCategory is already covered by any existing category in 'categories'
      if (!categories.some(existing => existing !== cleanedCategory && existing.startsWith(cleanedCategory))) {
        categories.push(cleanedCategory);
      }
    });
  }

  // ISBNs
  let isbns = '';
  if (book.isbn_list) {
    isbns = book.isbn_list.split(', ').map((isbn, ix) => (
      <div className="isbn" key={`isbn-${ix}`}>{isbn}</div>
    ))
  }

  const isCoverLarge = imgSizes.includes('sm') && book.img_checked;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  published.setHours(0, 0, 0, 0);
  const preOrderText = today < published ? 'Pre-order' : 'Order';

  const handleFavoriteChange = async () => {
    if (!isLoggedIn) return;
    const userInfoData = localStorage.getItem('userInfo');
    if (!userInfoData) return;
    const userInfo = JSON.parse(userInfoData);
    if (!userInfo) return;
  
    const newIsFav = !isFav;
    setIsFav(newIsFav);
  
    const favData = {
      book_id: book.id,
      email: userInfo.email,
      token: userInfo.token,
      is_favorite: newIsFav,
    };
  
    try {
      await saveFavorite(favData); // Perform the async operation.
    } catch (error) {
      console.error("Error:", error);
      setIsFav(!newIsFav); // Revert the state if the API call fails.
    }
  };
  
  const saveFavorite = async (favData) => {
    try {
      await axios.post("https://upcomingbook.com/api/toggle-favorite", favData);
    } catch (error) {
      throw error; // Re-throw to handle in `handleFavoriteChange`.
    }
  };
  
  const favClass = "fav-toggle float-end bi bi-bookmark" + ((isFav || false) ? '-fill text-violet' : '');
  const favTitle = isFav ? 'Remove from Favorites' : 'Add to Favorites';

  const imgSrc = `https://www.upcomingbook.com/images/books/${isCoverLarge ? 'md' : 'tmb'}/${book.slug}.jpg`;

  return (
    <>
      <Helmet>
          <title>{book.title} by {book.authors.replace(';', ',')} - Upcoming Book</title>
      </Helmet>
      <div className="d-sm-flex mt-4 gap-5 book-page">
        <div className="left">
          <img src={imgSrc} className={`d-block mx-auto book-image-${isCoverLarge ? 'md' : ''}`} alt={book.title} />

          <Retailers book={book} display="d-none d-sm-block" preOrderText={preOrderText} isCoverLarge={isCoverLarge} />
        </div>
        <div className="book-info">
          <div className="book-header mt-4 mt-sm-0 mb-4">
            <OverlayTrigger overlay={ <Tooltip>{favTitle}</Tooltip> }>
              <i className={favClass} onClick={isLoggedIn ? handleFavoriteChange : handleShowLoginModal}></i>
            </OverlayTrigger>
            <h1>{book.title}</h1>
            {book.info.subtitle && (
              <h3 className="text-secondary">{book.info.subtitle}</h3>
            )}
          </div>

          <p>by {authors}</p>

          <p>
            <Link to={`/date/${publishedYmd}`} className="text-black">
              <i className="bi bi-calendar-event me-2 text-violet"></i>
              <time dateTime={publishedYmd}>{formattedDate}</time>
            </Link>
            {book.info.publisher && (
              <>
                <i className="bi bi-dot mx-2 text-muted"></i>
                <span>{book.info.publisher}</span>
              </>
            )}
          </p>

          <article 
            className="book-description" 
            ref={descriptionRef}
            style={{ 
              maxHeight: isDescriptionExpanded ? '1000px' : '130px',
              borderBottomWidth: isDescriptionOverflowing || isDescriptionExpanded ? '1px' : '0'
            }}
            dangerouslySetInnerHTML={{ __html: book.description }} 
          />
          {(isDescriptionOverflowing || isDescriptionExpanded) && (
            <button className="description-toggle" onClick={handleDescriptionToggle}>
              {isDescriptionExpanded ? 'less' : 'more'}
            </button>
          )}
          <div className="mt-3"></div>

          <Retailers book={book} display="d-block d-sm-none w-100" preOrderText={preOrderText} isCoverLarge={isCoverLarge}  />

          {categories && (
            <>
              <div className="fw-bold text-violet mt-2">Categories</div>
              <section className="book-categories mt-2">
                {renderCategoryLinks(categories)}
              </section>
            </>
          )}

          {authorsInfo && authorsInfo.length > 0 && (
            <>
              <div className="about-authors-title fw-bold text-violet mt-3 mb-2">
                About author{authors.length > 1 ? 's' : ''}
              </div>
              <article className="about-authors-content" 
                ref={aboutAuthorsRef}
                style={{ 
                  maxHeight: isAboutAuthorsExpanded ? '1000px' : '130px',
                  borderBottomWidth: isAboutAuthorsOverflowing || isAboutAuthorsExpanded ? '1px' : '0'
                }}>
                {authorsInfo.map((authorInfo) => (
                  <div key={authorInfo.slug}>
                    {authors.length > 1 ? <p className="fw-bold mb-2">{authorInfo.name}</p> : ''}
                    <div>
                      {authorInfo.bio}
                    </div>
                  </div>
                ))}
              </article>
              {(isAboutAuthorsOverflowing || isAboutAuthorsExpanded) && (
                <button className="about-authors-toggle" onClick={handleAboutAuthorsToggle}>
                  {isAboutAuthorsExpanded ? 'less' : 'more'}
                </button>
              )}

            </>
          )}

          {Number(book.info.pageCount) > 0 && (
            <p className="mt-3">
              <i className="bi bi-file-earmark d-inline-block flip-vertical me-2"></i>
              {Number(book.info.pageCount).toLocaleString()} pages
            </p>
          )}

          {isbns && (
            <div className="my-3">ISBN {isbns}</div>
          )}

          {categories && <RelatedBooks slug={book.slug} limit="20" isLoggedIn={isLoggedIn} handleShowLoginModal={handleShowLoginModal} />}

        </div> {/* .book-info */}


      </div> {/* .book-page */}
      {process.env.NODE_ENV === 'development' && (
        <AdminBook book={book} />
      )}
    </>
  );
};

export default BookPage;
