import React from 'react';
import { Helmet } from 'react-helmet-async';

const Terms = () => (
    <>
      <Helmet>
        <title>Terms of Service - Upcoming Book</title>
      </Helmet>

      <article id="terms">
        <h1 className="h4 mt-4">Terms of Service</h1>

        <p>Last updated: November 17, 2024</p>
        <p>
            Welcome to UpcomingBook.com. By accessing or using our website, you agree to comply with and be bound by the following Terms of Service. 
            Please read these terms carefully.
        </p>

        <p className="fw-bold">1. Acceptance of Terms</p>
        <p>By using this website, you confirm that you accept these terms and agree to abide by them. If you do not agree, please do not use our website.</p>

        <p className="fw-bold">2. Use of the Website</p>
        <p>You agree to use UpcomingBook.com for lawful purposes only. You must not use our site:
            <ul>
                <li>In any way that breaches applicable laws or regulations.</li>
                <li>To transmit unsolicited or unauthorized advertising or promotional material.</li>
                <li>To engage in any conduct that restricts or inhibits anyone’s use or enjoyment of the site.</li>
            </ul>
        </p>

        <p className="fw-bold">3. Intellectual Property Rights</p>
        <p>All content, trademarks, and data on this website, including text, graphics, logos, and software, are the property of UpcomingBook.com or its licensors and are protected by intellectual property laws.</p>

        <p className="fw-bold">4. User Accounts</p>
        <p>To access certain features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

        <p className="fw-bold">5. Limitation of Liability</p>
        <p>UpcomingBook.com will not be liable for any indirect, incidental, or consequential damages arising from your use of the site.</p>

        <p className="fw-bold">6. Modifications to the Service</p>
        <p>We reserve the right to modify or discontinue any part of our service without prior notice. We will not be liable if any part of the site is unavailable at any time.</p>

        <p className="fw-bold">7. Changes to the Terms</p>
        <p>We may update these Terms of Service from time to time. Any changes will be posted on this page, and the date of the update will be indicated. Your continued use of the site after changes have been posted constitutes acceptance of the updated terms.</p>

        <p className="fw-bold">8. Governing Law</p>
        <p>These terms shall be governed by and construed in accordance with the laws of Russian Federation, without regard to its conflict of law principles.</p>

        <p className="fw-bold">9. Contact Us</p>
        <p>For any questions or concerns regarding these Terms of Service, please contact us at: support@upcomingbook.com</p>

        <p>Thank you for visiting UpcomingBook.com.</p>

      </article>
    </>
);

export default Terms;
