import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const PageNotFound = () => {
    const location = useLocation();

    return (
        <>
            <Helmet>
                <title>Page Not Found - Upcoming Book</title>
            </Helmet>
            <div className="text-center">
                <Alert variant="danger" className="d-inline-block mt-5">
                    <p>This page doesn't exist on our server</p>
                    <p>
                        <pre>{location.pathname}</pre>
                    </p>
                </Alert>
            </div>
        </>
    );
};

export default PageNotFound;
