import React, { useState } from 'react';
import axios from 'axios';

const AdminBookImage = ({ size, slug }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [imageExists, setImageExists] = useState(true);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const handleImageError = () => {
    setImageExists(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.delete(`https://upcomingbook.com/api/admin/delete-image`, {
        params: { slug, size, admin_key: process.env.REACT_APP_ADMIN_KEY, _: Date.now() },
      });
      if (response.status === 200) {
        setIsDeleted(true);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div
      className="col-2 position-relative text-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
        <div className="small">{size}</div>

        {imageExists && !isDeleted ? (
          <img
              src={`https://upcomingbook.com/images/books/${size}/${slug}.jpg?_=${Date.now()}`}
              className={`${isHovered ? 'opacity-50' : ''}`}
              width="128"
              alt={size}
              onLoad={handleImageLoad}
              onError={handleImageError}
          />
        ) : (
          <p className="mt-5">No image</p>
        )}
        {isHovered && imageExists && !isDeleted && (
            <button
            className="btn btn-sm btn-danger position-absolute top-50 start-50 translate-middle"
            onClick={handleDelete}
            disabled={isDeleting}
            >
                {isDeleting ? 'Deleting...' : 'Delete'}
            </button>
        )}
        <div>
          {dimensions.width > 0 && dimensions.height > 0 && imageExists && !isDeleted && (
            <div className="small">
              {dimensions.width} × {dimensions.height}
          </div>
          )}
        </div>
    </div>
  );
};

export default AdminBookImage;
