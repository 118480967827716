import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import AdminBookImage from './AdminBookImage.js';
import './AdminBook.css';

const AdminBook = ({book, mode}) => {
    const [jsonVisible, setJsonVisible] = useState(false);
    const [isImgChecked, setIsImgChecked] = useState(book.img_checked);
    const [isDeleting, setIsDeleting] = useState(false);
  
    const handleDeleteImages = async () => {
        setIsDeleting(true);
        try {
          const response = await axios.delete(`https://upcomingbook.com/api/admin/delete-image`, {
            params: { 
                slug: book.slug, 
                size: 'sm-md-lg', 
                admin_key: process.env.REACT_APP_ADMIN_KEY, _: Date.now() 
            },
          });
          if (response.status === 200) {
            //setIsDeleted(true);
          }
        } catch (error) {
          console.error('Error deleting image:', error);
        } finally {
          setIsDeleting(false);
        }
      };
        
    const handleCheckboxChange = async () => {
        try {
            const response = await axios.post('https://upcomingbook.com/api/admin/img-checked', {
                slug: book.slug,
                img_checked: isImgChecked ? 0 : 1,
                admin_key: process.env.REACT_APP_ADMIN_KEY
            });

            if (response.status === 200) {
                setIsImgChecked(!isImgChecked);
            } else {
                console.error('Failed to update img_checked');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Info
    const toggleJson = () => {
        setJsonVisible(!jsonVisible);
    };

    useEffect(() => {
    }, [isImgChecked]);
    
    const sectionClass = mode === 'short' ? '' : 'admin-book mt-5';

    return (
        <section className={sectionClass}>
            {mode !== 'short' &&
                <>
                    <div className="book-header">
                        <i className="bi bi-square-fill float-end"></i>
                        <h3>Admin</h3>
                    </div>

                    <div className="mt-4">
                    <a href={`https://www.google.ru/books/edition/_/${book.api_id}`} 
                        target="_blank" className="text-muted" rel="noreferrer" title="Google Books">GB</a>
                    <a href={`https://www.googleapis.com/books/v1/volumes/${book.api_id}`} 
                        target="_blank" className="text-muted ms-3" rel="noreferrer" title="Google Books">GB JSON</a>
                    </div>
                </>
            }
            {(mode !== 'short' || !isImgChecked) &&
                <>
                    <div className="mt-3">
                        { mode === 'short' ? `${book.slug} ${book.published_date}` : '' }
                    </div>
                    <div className="row">
                        {['tmb', 'sm', ].map((size) => <AdminBookImage size={size} slug={book.slug} key={size} /> )}
                        <div className="col-2">
                            <div>
                                <Button variant="danger" size="sm"
                                        onClick={handleDeleteImages}
                                        disabled={isDeleting}>
                                    { isDeleting ? 'Deleting...' : 'Delete sm, md, lg' }
                                </Button>
                            </div>
                            <div className="form-check pt-2">
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    id={book.slug} 
                                    checked={isImgChecked} 
                                    onChange={handleCheckboxChange} 
                                />
                                <label className="form-check-label" htmlFor={book.slug}>
                                    Images checked
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            }
            {mode !== 'short' &&
                <div className="mt-4">
                    <Button variant="link" size="sm" className="px-0"
                        onClick={(e) => { e.preventDefault(); toggleJson(); }}>
                        {jsonVisible ? "Hide data" : "Show data"}
                    </Button>
            
                    {jsonVisible && (
                        <p className="mt-4">
                            <pre>{JSON.stringify(book.info, null, 4)}</pre>
                        </p>
                    )}
                </div>
            }

        </section>
    );
};

export default AdminBook;