import './Retailers.css';

const Retailers = ({ book, display, preOrderText, isCoverLarge }) => {

    const search_term = encodeURI(`${book.title} ${book.authors}`);

    let isbn = '';
    if (book.isbn_list?.length >= 10) {
        const isbn_list = book.isbn_list.split(', ');
        if (isbn_list.length > 1) isbn = isbn_list[1];
        else isbn = isbn_list[0];
    }
    const reseller_img_dir = 'https://www.upcomingbook.com/images/retailers';

    return (
        <div className={`retailers ${isCoverLarge ? 'retailers-wide' : ''} mt-4 ${display}`}>
            <div className="fw-bold text-violet mb-3">{preOrderText}</div>
            <a href={`https://www.amazon.com/s?k=${search_term}&tag=upcomingbook-20`} target="_blank" rel="noreferrer">
                <img src={`${reseller_img_dir}/amazon.svg`} className="amazon" alt="Amazon" />
            </a>
            <a href={`https://www.barnesandnoble.com/s/${search_term}`} className="mb-2" target="_blank" rel="noreferrer">
                <img src={`${reseller_img_dir}/bn.svg`} className="bn" alt="Barnes &amp; Noble" />
            </a>
            <a href={`https://www.booksamillion.com/search2?query=${search_term}`} className="mb-3" target="_blank" rel="noreferrer">
                <img src={`${reseller_img_dir}/bam.png`} className="bam" alt="Books-A-Million" />
            </a>
            <a href={`https://www.powells.com/searchresults?keyword=${search_term}`} className="mb-2" target="_blank" rel="noreferrer">
                <img src={`${reseller_img_dir}/powells.jpg`} className="powells" alt="Powell's" />
            </a>
            { isbn.length >= 10 &&
                <a href={`https://bookshop.org/a/108492/${isbn}`} target="_blank" rel="noreferrer">
                    <img src={`${reseller_img_dir}/bookshop.svg`} className="bookshop" alt="Bookshop" />
                </a>
            }
            <a href={`https://www.abebooks.com/servlet/SearchResults?kn=${search_term}`} target="_blank" rel="noreferrer">
                <img src={`${reseller_img_dir}/abebooks.png`} className="abebooks" alt="AbeBooks" />
            </a>
        </div>
    );
};

export default Retailers;
