import React from 'react';
import './AboutAuthor.css';

const AboutAuthor = ({ author }) => {
    if (!author || !author.bio) return '';

    const links = [];
    if (author.wikipedia) links.push({
        text: 'Wikipedia',
        title: 'Wikipedia',
        href: author.wikipedia
    });

    const linkBlocks = links.map((link) => {
        return (
            <a href={link.href} title={link.title} target="_blank" rel="noreferrer">
                {link.text}
            </a>
        );
    });

    return (
        <article className="about-author-content">
          <div className="about-author-title fw-bold text-violet mt-3 mb-2">
            About {author.name}
          </div>
          {author.photo && 
            <img src={`https://upcomingbook.com/images/authors/${author.slug}.jpg`} className="img-thumbnail mb-3" alt={author.name} />
          }
          <div className="author-bio">{author.bio}</div>
          <div className="author-links">{linkBlocks}</div>
        </article>
    );
};

export default AboutAuthor;
