import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
import './LoginModal.css';

const LoginModal = ({ show, onHide, onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [isVerifyingCode, setIsVerifingCode] = useState(false);
    const [error, setError] = useState('');

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => {
            onLoginSuccess(response, 'google');
            onHide(); // Close the modal after successful login
        },
        onError: (error) => {
          console.error("Login Failed:", error);
        },
    });

    const handleAmazonLogin = () => {
        window.amazon.Login.authorize(
            {
                scope: 'profile',
                client_id: process.env.REACT_APP_AMAZON_APP_ID,
            },
            async (response) => {
                if (response.error) {
                    console.error('Amazon Login Failed:', response.error);
                    return;
                }
                onLoginSuccess(response, 'amazon');
                onHide(); // Close the modal after successful login
            }
        );
    };

    const handleSendAccessCode = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email.');
            return;
        }

        try {
            setIsSendingCode(true);
            setError('');
            await sendAccessCode(email); // Call the function to send the access code
            setIsSendingCode(false);
            setIsCodeSent(true);
        } catch (err) {
            console.error("Error sending access code:", err);
            setError('Failed to send access code. Please try again.');
            setIsSendingCode(false);
        }
    };

    const sendAccessCode = async (email) => {
        const response = await fetch('https://upcomingbook.com/api/send-access-code', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
    
        if (!response.ok) {
            throw new Error('Failed to send access code');
        }
    };

    const handleVerifyAccessCode = (e) => {
        e.preventDefault();
        setIsVerifingCode(true);
        validateUser();
        setIsVerifingCode(false);
    };

    const validateUser = async () => {
        setError('');
        if (isNaN(accessCode) || accessCode.length !== 6) {
            setError('Invalid code');
            return;
        }
        try {
            const response = await axios.post('https://upcomingbook.com/api/validate-user', { code: accessCode, email });
            if (response.data.valid && response.data.token) {
                onLoginSuccess({ email, token: response.data.token }, 'code');
                setError('');
                onHide();
            } else {
                setError('Code is invalid or has expired');
            }
        } catch (err) {
            let errorMessage = 'Something went wrong. Please try again later.';
    
            // Check for server error response
            if (err.response) {
                const status = err.response.status;
    
                if (status === 400) {
                    // Handle client error from backend
                    errorMessage = err.response.data.error || 'Invalid data provided.';
                } else if (status === 429) {
                    errorMessage = 'Too many requests. Please try again later.';
                } else if (status >= 500) {
                    errorMessage = 'Server error. Please try again later.';
                }
            }
            setError(errorMessage);
        }
    };

    useEffect(() => {
        // Initialize Amazon login
        window.amazon.Login.setClientId(process.env.REACT_APP_AMAZON_APP_ID);
    }, []);    

    return (
        <Modal className="login-modal" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Sign in</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pb-4">
                <p>Use your favorite social network</p>
                <Row className="login-buttons">
                    <Col>
                        <Button variant="primary" className="w-100" onClick={() => googleLogin()}>
                            <i className="bi bi-google me-2"></i> Google
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="primary" className="w-100" onClick={handleAmazonLogin}>
                            <i className="bi bi-amazon me-2"></i> Amazon
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Body>
                <div className="or">OR</div>
                <div className="email-login">
                    <Form onSubmit={isCodeSent ? handleVerifyAccessCode : handleSendAccessCode}>
                        {!isCodeSent ? (
                            <>
                                <Form.Label>Get access code by email</Form.Label>
                                <div className="d-flex gap-2">
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        disabled={isSendingCode ? 'disabled' : ''}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Button 
                                        variant="primary" 
                                        type="submit" 
                                        className="text-nowrap"
                                        disabled={isSendingCode ? 'disabled' : ''}
                                    >
                                        <i className="bi bi-envelope-at me-2"></i> Send code
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <>
                                <Form.Label>We sent a code to {email}, please enter it below:</Form.Label>
                                <div className="d-flex gap-2">
                                    <Form.Control
                                        type="text"
                                        className="text-violet fw-bold"
                                        placeholder="Enter the access code"
                                        value={accessCode}
                                        disabled={isVerifyingCode ? 'disabled' : ''}
                                        onChange={(e) => setAccessCode(e.target.value)}
                                    />
                                    <Button 
                                        variant="primary" 
                                        type="submit"
                                        className="text-nowrap"
                                        disabled={isVerifyingCode ? 'disabled' : ''}
                                    >
                                        Verify Code
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                    {error && <div className="alert alert-danger mt-3 mb-0 py-2">{error}</div>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;