import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Alert } from 'react-bootstrap';
import axios from 'axios';
import AdminBook from './AdminBook';

const AdminCheckImages = () => {
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
        async function getBooks() {
            let params = {
                mode: 'admin-img-not-checked',
                limit: 50
            };
            if (process.env.NODE_ENV === 'development') {
                params.admin_key = process.env.REACT_APP_ADMIN_KEY;
            }
            setIsLoading(true);
            const response = await axios.get('https://upcomingbook.com/api/books', {
                params: params
            });
            const data = response.data;
            setBooks(data.books);
            setIsLoading(false);
        }
        getBooks();
    }, []);

    return (
        <>
            <Helmet>
                <title>Check images - Admin - Upcoming Book</title>
            </Helmet>

            {isLoading && 
                <div className="small text-muted">Loading...</div>
            }
            {books.length === 0 && !isLoading &&
                <div className="text-center">
                    <Alert variant="primary" className="d-inline-block mt-5">No books with images not checked</Alert>
                </div>
            }
            {books.map((book) => <AdminBook book={book} mode="short" />)}
        </>
    );
}

export default AdminCheckImages;