import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
//import { debounce } from 'lodash';
import AdminCheckImages from './components/AdminCheckImages';
import BookList from './components/BookList';
import BookPage from './components/BookPage';
import MyNavbar from './components/MyNavbar';
import LoginWithHash from './components/LoginWithHash';
import GoToTopButton from './components/GoToTopButton';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import PageNotFound from './pages/PageNotFound';
import AboutUs from './pages/AboutUs';
import './App.css';

function App() {

  const [userInfo, setUserInfo] = useState(null);
  const [query, setQuery] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);
  
  const userInfoUrl = {
    google: 'https://www.googleapis.com/oauth2/v2/userinfo',
    amazon: 'https://api.amazon.com/user/profile'
  }

  const handleLoginSuccess = async (response, provider) => {
    if (provider === 'code') {
      handleLinkLoginSuccess(response.email, response.token);
      return;
    }
    if (response.access_token) {
      try {
        // Fetch user info from Google
        const userInfoResponse = await fetch(userInfoUrl[provider], {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });
  
        const userInfo = await userInfoResponse.json();
        //console.log("Fetched User Info:", userInfo);
  

        // Send user info to the backend to save in the database and receive a login token
        let token = null;
        try {
          const response = await axios.post('https://upcomingbook.com/api/user', {
            email: userInfo.email,
            name: userInfo.name,
            photo_url: userInfo.picture || '',
            login_source: provider
          }, {
            headers: {
              'Content-Type': 'application/json',
            }
          });
      
          token = response.data.token; // Assuming the response contains a token field
          if (!token) throw new Error('Invalid token');
        } catch (error) {
          console.error('Error sending user data:', error.response?.data || error.message);
          throw error;
        }

        userInfo.token = token;
        setUserInfo(userInfo);
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

      } catch (error) {
        console.error("Error fetching user info:", error);
        setUserInfo(null);
        localStorage.removeItem("userInfo");
      }
    } else {
      console.error("No access_token found in response.");
      setUserInfo(null);
      localStorage.removeItem("userInfo");
    }
  };

  const handleLinkLoginSuccess = (email, token) => {
    const userInfo = { 
      email: email,
      token: token
    }
    setUserInfo(userInfo);
    localStorage.setItem("userInfo", JSON.stringify(userInfo));
  }

  const handleSearch = (event) => {
    if (event.type === 'keydown' && event.key === 'Enter') {
      event.preventDefault();
      setQuery(event.target.value); // Trigger search on Enter
    } else if (event.type === 'input') {
      if (!event.target.value.trim()) {
        setQuery(''); // Clear search when input is cleared
      }
    }
  };
    
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    const storedUserInfo = localStorage.getItem("userInfo");

    if (storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
    }

    // Event listener for changes in localStorage across tabs/windows
    const handleStorageChange = (event) => {
      if (event.key === "userInfo") {
        const updatedUserInfo = localStorage.getItem("userInfo");
    
        if (updatedUserInfo) {
          setUserInfo(JSON.parse(updatedUserInfo));
        } else {
          setUserInfo(null);
        }
      }
    };
    
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [location]); // useEffect

  const handleLogout = () => {
    setUserInfo(null);
    localStorage.removeItem("userInfo");
  };

  const isLoggedIn = userInfo && userInfo.email && userInfo.token;

  const renderBookList = (additionalProps = {}) => (
    <BookList isLoggedIn={isLoggedIn} handleShowLoginModal={handleShowLoginModal} query={query} {...additionalProps} />
  );
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <HelmetProvider>
          <MyNavbar query={query} 
                    onSearch={handleSearch} 
                    isLoggedIn={isLoggedIn} 
                    handleLoginSuccess={handleLoginSuccess} 
                    showLoginModal={showLoginModal}
                    handleShowLoginModal={handleShowLoginModal}
                    handleCloseLoginModal={handleCloseLoginModal}
                    onLogout={handleLogout}
                    userInfo={userInfo} />
          <Routes>
            <Route path="/" element={renderBookList({ query })} />
            <Route path="/category/:category_slug" element={renderBookList()} />
            <Route path="/by/:author_slug" element={renderBookList()} />
            <Route path="/date/:date" element={renderBookList()} />
            <Route path="/favorites" element={renderBookList({ mode: 'favorites' })} />
            <Route path="/released" element={renderBookList({ mode: 'past' })} />
            <Route path="/book/:slug" element={<BookPage isLoggedIn={isLoggedIn} handleShowLoginModal={handleShowLoginModal} />} />
            <Route path="/access" element={<LoginWithHash onLogin={handleLinkLoginSuccess} />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/about-us" element={<AboutUs />} />

            <Route path="/admin/check-img" element={<AdminCheckImages />} />

            <Route path="*" element={<PageNotFound />} />

          </Routes>
          <GoToTopButton />
      </HelmetProvider>          
    </GoogleOAuthProvider>
  );
}

export default App;
