import React from 'react';
import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => (
    <>
        <Helmet>
            <title>Privacy Policy - Upcoming Book</title>
        </Helmet>
        <article id="privacy">
            <h1 className="h4 mt-4">Privacy Policy</h1>

            <p>Last updated: November 17, 2024</p>
            <p>
                Welcome to UpcomingBook.com. We value your privacy and strive to protect your personal information. 
                This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website.
            </p>

            <p className="fw-bold">1. Information We Collect</p>

            <p>We may collect the following types of information:</p>
            <ul>
                <li>
                    <strong>Personal Data</strong>: Name, email address, and other contact details you provide when signing up or communicating with us.
                </li>
                <li>
                    <strong>Usage Data</strong>: Information about your interactions with our site, such as pages viewed, time spent on the site, and IP address.
                </li>
                <li>
                    <strong>Cookies and Tracking</strong>: We use cookies and similar tracking technologies to monitor and analyze usage and improve your experience.
                </li>
            </ul>

            <p className="fw-bold">2. How We Use Your Information</p>

            <p>We may use the information we collect to:</p>
            <ul>
                <li>Provide and maintain our service.</li>
                <li>Personalize and improve your experience.</li>
                <li>Communicate with you regarding updates, promotions, and other information.</li>
                <li>Analyze site usage to enhance functionality and security.</li>
            </ul>

            <p className="fw-bold">3. Sharing and Disclosure of Information</p>
            <p>We do not sell or rent your personal information to third parties. We may share information with:</p>
            <ul>
                <li>
                    <strong>Service Providers</strong>: Trusted third-party vendors who assist in operating our website and services.
                </li>
                <li>
                    <strong>Legal Requirements</strong>: When disclosure is necessary to comply with legal obligations or protect our rights.
                </li>
            </ul>

            <p className="fw-bold">4. Data Security</p>

            <p>
                We implement a variety of security measures to maintain the safety of your personal information. 
                However, no electronic storage method or transmission over the internet is 100% secure.
            </p>
            
            <p className="fw-bold">5. Your Data Rights</p>
            <p>Depending on your location, you may have rights to access, correct, or delete your personal data. Please contact us for assistance with such requests.</p>

            <p className="fw-bold">6. Changes to This Privacy Policy</p>
            <p>We may update this policy from time to time. Any changes will be posted on this page, and the date of the update will be indicated.</p>

            <p className="fw-bold">7. Contact Us</p>
            <p>If you have questions or concerns regarding this Privacy Policy, please contact us at: support@upcomingbook.com</p>

            <p>Thank you for visiting UpcomingBook.com.</p>

        </article>
    </>

);

export default PrivacyPolicy;
