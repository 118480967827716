import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Dropdown, Form, FormControl, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import LoginModal from './LoginModal';
//import UserModal from './UserModal';
import './MyNavbar.css';

const MyNavbar = ({ onSearch, isLoggedIn, userInfo, showLoginModal, onLogout, handleLoginSuccess, handleShowLoginModal, handleCloseLoginModal }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission on Enter
      }
      // Navigate to '/' if we're not on it
      if (location.pathname !== '/') {
          navigate('/');
      }
    };

    //const [showUserModal, setShowUserModal] = useState(false);
    //const handleShowUserModal = () => setShowUserModal(true);
    //const handleCloseUserModal = () => setShowUserModal(false);
  
    let loginBtnClass = "order-2 pe-0 float-end text-black";

    return (
        <>
            <div className="d-sm-flex align-items-center mt-3 gap-3 top-bar">
                <Link to="/">
                    <img src="/images/upcoming_book.svg" width="200" height="auto" alt="Upcoming Book" />
                </Link>
                {isLoggedIn ? (
                    <>
                        <Link to="/favorites" className="d-none d-sm-inline-block text-black text-decoration-none order-2">
                            <OverlayTrigger overlay={ <Tooltip>My Favorites</Tooltip> } placement="bottom">
                                <i className="bi bi-bookmark-fill top-nav-bookmark"></i>
                            </OverlayTrigger>
                        </Link>
                        <Dropdown className={loginBtnClass} id="userBtn">
                            <Dropdown.Toggle variant="link" className="p-0">
                                {userInfo.picture?.length ? (
                                    <Image
                                        src={userInfo.picture}
                                        className="user-image"                                   
                                        title={userInfo.email}
                                        alt={userInfo.email}
                                        roundedCircle
                                    />
                                ) : (
                                    <div className="letter-avatar">{userInfo.email.toUpperCase()[0]}</div>
                                )}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>{userInfo.email}</Dropdown.Header>
                                <Dropdown.Item as={Link} to="/favorites" className="d-block d-sm-none">
                                    <i className="bi bi-bookmark-fill"></i>
                                    My Favorites
                                </Dropdown.Item>
                                {/*<Dropdown.Item as="button" onClick={handleShowUserModal}>Preferences</Dropdown.Item>*/}
                                <Dropdown.Divider className="d-block d-sm-none" />
                                <Dropdown.Item as="button" onClick={onLogout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    Sign out
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                ) : (
                    <Button
                        variant="link"
                        id="userBtn"
                        className={loginBtnClass}
                        onClick={handleShowLoginModal}
                    >
                        <strong className="text-violet">Sign in</strong>
                    </Button>
                )}
                <Form className="search-form flex-grow-1 mt-3 mt-sm-0" onKeyDown={handleKeyPress}>
                    <FormControl
                        type="search"
                        placeholder="Search by title, author"
                        className="mr-2 search-input"
                        aria-label="Search"
                        onKeyDown={onSearch} // For handling Enter key
                        onInput={onSearch}   // For handling paste and clear events
                    />
                </Form>
            </div>
            
            <LoginModal show={showLoginModal} onHide={handleCloseLoginModal} onLoginSuccess={handleLoginSuccess} />
            {/*<UserModal show={showUserModal} onHide={handleCloseUserModal} user={userInfo} />*/}
        </>
    );
};

export default MyNavbar;