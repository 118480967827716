import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Alert, Pagination } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Book from './Book';
import AboutAuthor from './AboutAuthor';
import './BookList.css';
import { renderCategoryLinks } from '../utils/renderLinks.js';

function BookList({ query, limit, isLoggedIn, handleShowLoginModal, mode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [pages, setPages] = useState(1);
  const location = useLocation();
  const path = location.pathname;

  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get('p'), 10) || 1;
  const [page, setPage] = useState(initialPage);
  
  let { author_slug, category_slug, date } = useParams();

  // Load books function
  const loadBooks = useCallback(async (searchQuery = '') => {
    try {
      setIsLoading(true);
      let params = {
        author_slug: author_slug,
        category_slug: category_slug,
        mode: mode,
        date: date,
        limit: limit,
        p: page,
        q: searchQuery
      };
      if (process.env.NODE_ENV === 'development') {
        params.admin_key = process.env.REACT_APP_ADMIN_KEY;
      }
      const userInfoData = localStorage.getItem('userInfo');
      if (userInfoData) {
        const userInfo = JSON.parse(userInfoData);
        params.u = userInfo.email;
        params.token = userInfo.token;
      }
  
      const response = await axios.get('https://upcomingbook.com/api/books', {
        params: params
      });

      const data = response.data;

      setBooks(data.books);
      setPages(data.pages || 1);
      setTitle(data.title);
      setAuthor(data.author || {});
      if (data.category && data.category.length > 0) {
        setBreadcrumbs(renderCategoryLinks([data.category], true));
      }
      
      // Set breadcrumbs if requested by date
      if (data.date && data.date.length >= 4) {
        const bcrumbs = [
          <Link to="/">Home</Link>
        ];
        let [date_y, date_m, date_d] = (data.date || '').split('-').map(part => {
          const num = parseInt(part, 10);
          return isNaN(num) ? null : num;
        });
        if (date_y >= 2024 && date_m >= 1 && date_m <= 12) {
          const link_y = `/date/${String(date_y)}`;
          bcrumbs.push(<Link to={link_y}>{String(date_y)}</Link>);
          if (date_d >= 1 && date_d <= 31) {
            const link_m = `/date/${String(date_y)}-${String(date_m).padStart(2, '0')}`;
            const date = new Date(date_y, date_m - 1);
            const month = date.toLocaleDateString('en-US', { month: 'long' });
            bcrumbs.push(<Link to={link_m}>{month}</Link>);
          }
        }
        setBreadcrumbs(bcrumbs.reduce((acc, link, i) => (i === 0 ? [link] : [...acc, <span className="mx-2" key={`chevron-${i}`}>/</span>, link]), []));
      }
      setErrorMessage('');
    } catch (error) {
      console.error("Error loading books:", error);
      setErrorMessage(error.response.data.error || error.response.data.message || error.message || '');
      setBooks([]);
    } finally {
      setIsLoading(false);
    }
  }, [author_slug, category_slug, date, limit, mode, page]);

  useEffect(() => {
    loadBooks(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, author_slug, category_slug, date, location.pathname, isLoggedIn, page]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    let currentPage = parseInt(searchParams.get('p'), 10) || 1;
    if (currentPage < 1) currentPage = 1;

    if (currentPage !== page) {
      setPage(currentPage); // Update state when URL changes
    }
  }, [location.search, page]);

  let paginationItems = [];
  const maxVisiblePages = 5; // Adjust as needed
  
  const createPaginationItem = (p) => (
    <Pagination.Item
      as={Link}
      to={`?p=${p}`}
      key={p}
      active={p === page}
      className={p === page ? 'd-block' : 'd-none d-sm-block'}
    >
      {p}
    </Pagination.Item>
  );
  
  if (pages <= maxVisiblePages) {
    // Show all pages if total pages are within the limit
    for (let p = 1; p <= pages; p++) {
      paginationItems.push(createPaginationItem(p));
    }
  } else {
    // Always show the first page
    paginationItems.push(createPaginationItem(1));
  
    // Show ellipsis if needed
    if (page > 3) {
      paginationItems.push(<Pagination.Ellipsis className="d-none d-sm-block" key="start-ellipsis" />);
    }
  
    // Show pages around the current page
    for (let p = Math.max(2, page - 2); p <= Math.min(pages - 1, page + 2); p++) {
      paginationItems.push(createPaginationItem(p));
    }
  
    // Show ellipsis if needed
    if (page < pages - 2) {
      paginationItems.push(<Pagination.Ellipsis className="d-none d-sm-block" key="end-ellipsis" />);
    }
  
    // Always show the last page
    paginationItems.push(createPaginationItem(pages));
  }


  return (
    <>
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          Upcoming Book
        </title>
      </Helmet>

      <div className="loader my-3" style={{visibility: isLoading ? 'visible' : 'hidden'}}></div>

      { !isLoggedIn && !query && !author_slug && !category_slug && !date && !mode && (
        <section className="top-banner">
          <div>
            Discover your next favorite read with our comprehensive list of <strong className="text-violet text-nowrap">upcoming book releases</strong>
            <div className="d-none d-md-inline">.</div>
          </div>
          <div className="d-none d-lg-block">Stay informed and never miss out on the most anticipated titles across all genres.</div>
          <div className="d-none d-md-block">Explore, plan, and add excitement to your reading journey!</div>
        </section>
      )}
      <div className="category-title">
        {title && (<h1 className="booklist-title">{title}</h1>)}
        {breadcrumbs && (<div className="breadcrumbs">{breadcrumbs}</div>)}
      </div>

      {
        errorMessage && !isLoading && (
          <div className="text-center mt-5">
            <Alert variant="danger" className="d-sm-inline-block">{errorMessage}</Alert>
          </div>
        )
      }
      {
        books.length === 0 && !isLoading && !errorMessage &&
        <div className="text-center mt-5">
          <Alert variant="primary" className="d-block d-sm-inline-block">No books found</Alert>
        </div>
      }

      <Row className={`books ${path.startsWith('/favorites') ? 'favorites' : ''} ${isLoading ? 'loading' : ''} mb-3`}>
          {books.map((book) => (
            <Col xs md={6} lg={4} xl={3} key={book.id}>
                <Book
                    id={book.id}
                    title={book.title}
                    authors={book.authors}
                    publishedDate={book.published_date}
                    slug={book.slug}
                    img_checked={typeof book.img_checked === 'undefined' ? true : book.img_checked}
                    isLoggedIn={isLoggedIn}
                    handleShowLoginModal={handleShowLoginModal}
                    isFavorite={book.is_favorite}
                    path={path}
                />
            </Col>
          ))}
      </Row>

      {/* Pagination */}
      {page && pages > 1 &&
        <Pagination className="justify-content-center">

          {/* First Page Button */}
          <Pagination.First 
            as={Link} 
            to={`?p=1`} 
            disabled={page === 1} />

          {/* Next Page Button */}
          <Pagination.Prev 
            as={Link} 
            to={`?p=${page - 1}`} 
            disabled={page === 1} />

          {/* Page Number Items */}
          {paginationItems}

          {/* Next Page Button */}
          <Pagination.Next
            as={Link}
            to={`?p=${page + 1}`}
            disabled={page === pages}
          />

          {/* Last Page Button */}
          <Pagination.Last
            as={Link}
            to={`?p=${pages}`}
            disabled={page === pages}
          />

        </Pagination>
      }

      {author && author.bio &&
        <AboutAuthor author={author} />
      }
    </>
  );
}

export default BookList;
