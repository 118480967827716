import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

const LoginWithHash = ({onLogin}) => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
  
    useEffect(() => {
        const hash = searchParams.get('hash');
        const email = searchParams.get('email');

        if (!email || !hash || hash.length !== 64) {
            setError('Invalid access link');
            return;
        }

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
            setError('Invalid email address');
            return;
        }

		const validateUser = async () => {
            try {
                const response = await axios.post('https://upcomingbook.com/api/validate-user', { hash, email });
                if (response.data.valid && response.data.token) {
					onLogin(email, response.data.token);
                    navigate('/', { replace: true });
                } else {
                    setError('Access link is invalid or has expired');
                }
            } catch (err) {
                //console.error(err);
				let errorMessage = 'Something went wrong. Please try again later';
				if (err.status === 429) errorMessage = 'Too many requests. Please try again later.';
                setError(errorMessage);
            }
        };

        validateUser();
    }, [searchParams, navigate, onLogin]);

    return (
		<>
            {error && <Alert variant="danger" className="mt-5 col col-sm-6 mx-auto">{error}</Alert>}
		</>	
    );
};

export default LoginWithHash;